
const filters = {
    "User Type" : {
        predefined: ["Organic", "Non-Organic"],
        buildFilter: (_filter, _value) => {
            if (_value === "Organic") {
                _filter.ua_type = "organic"
                return true;
            } else if (_value === "Non-Organic") {
                _filter.ua_type = "non-organic";
                return true;
            }
            return false;
        }
    },
    "Country" : {
        defaultText: "Enter country codes...",
        buildFilter: (_filter, _value) => {
            if (typeof _value === "string" ) {
                let tokens = _value.split(",");
                for(let i  = 0; i < tokens.length; i++) {
                    tokens[i] = tokens[i].trim().toUpperCase();
                }
                _filter.country = tokens;
                return true;
            }
            return false;
        }
    },
    "Platform" : {
        predefined: ["iOS", "Android"],
        buildFilter: (_filter, _value) => {
            if (_value === "iOS") {
                _filter.platform = "IPhonePlayer"
                return true;
            } else if (_value === "Android") {
                _filter.platform = "Android";
                return true;
            }
            return false;
        }
    },
    "Install Version" : {
        defaultText: "Enter install versions...",
        buildFilter: (_filter, _value) => {
            if (typeof _value === "string" ) {
                let tokens = _value.split(",");
                for(let i  = 0; i < tokens.length; i++) {
                    tokens[i] = tokens[i].trim().toUpperCase();
                }
                _filter.install_version = tokens;
                return true;
            }
            return false;
        }
    },
    "Ad Network" : {
        predefined: ["organic", "Facebook","Applovin","Unity Ads"],
        buildFilter: (_filter, _value) => {
            if (_value === "organic") {
                _filter.ua_media_source = {null: true};
                return true;
            } else if (_value === "Facebook") {
                _filter.ua_media_source = "Facebook Ads";
                return true;
            } else if (_value === "Applovin") {
                _filter.ua_media_source = "applovin";
                return true;
            } else if (_value === "Unity Ads") {
                _filter.ua_media_source = "Unity Ads";
                return true;
            }
            return false;
        }
    },
    "Applovin site_id": {
        defaultText: "Enter site ids...",
        buildFilter: (_filter, _value) => {
            if (typeof _value === "string" ) {
                let tokens = _value.split(",");
                for(let i  = 0; i < tokens.length; i++) {
                    tokens[i] = tokens[i].trim();
                }
                _filter.ua_media_source = 'applovin';
                _filter.ua_adset = tokens;
                return true;
            }
            return false;
        }
    },
    "UnityAds site_id": {
        defaultText: "Enter site ids...",
        buildFilter: (_filter, _value) => {
            if (typeof _value === "string" ) {
                let tokens = _value.split(",");
                for(let i  = 0; i < tokens.length; i++) {
                    tokens[i] = tokens[i].trim().toUpperCase();
                }
                _filter.ua_media_source = 'Unity Ads';
                _filter.ua_adset = tokens;
                return true;
            }
            return false;
        }
    },
    "Campaign Id" : {
        defaultText: "Enter Campaign-Id...",
        buildFilter: (_filter, _value) => {
            if (typeof _value === "string" ) {
                let tokens = _value.split(",");
                for(let i  = 0; i < tokens.length; i++) {
                    tokens[i] = tokens[i].trim().toUpperCase();
                }
                _filter.ua_campaign = tokens;
                return true;
            }
            return false;
        }
    }
};

function getFilterTypes() {
    let filterList = [];
    for (let filter in filters) {
        filterList.push(filter);
    }
    filterList.sort();
    return filterList;
}

function isSupportedFilter(_filterType) {
    if(filters[_filterType]) {
        return true;
    }
    return false;
}

function hasPredefinedValues(_filterType) {
    if (filters[_filterType] && filters[_filterType].predefined) {
        return true;
    }
    return false;
}

function getDefaultText(_filterType) {
    if (filters[_filterType] && filters[_filterType].defaultText) {
        return filters[_filterType].defaultText;
    }
    return "Enter data...";
}

function getPredefinedValues(_filterType) {
    if (filters[_filterType] && filters[_filterType].predefined) {
        return filters[_filterType].predefined;
    }
    return [];
}

function buildFilter(_filters) {
    let filter = {};
    let hasFilter = false;
    for (let i = 0; i < _filters.length; i++) {
        const filterEntry = _filters[i];
        if (filters[filterEntry.type]) {
            hasFilter |= filters[filterEntry.type].buildFilter(filter, filterEntry.value);
        }
    }
    if (hasFilter) {
        return filter;
    }
    return null;
}

function mergeFilters(_filter, _extFilter) {
    let filter = JSON.parse(JSON.stringify(_filter));
    if (!filter) {
        filter = {};
    }
    if (!_extFilter) {
        _extFilter = {};
    }
    for(let key in _extFilter) {
        filter[key] = _extFilter[key];
    }
    return filter;
}

export {
    getFilterTypes,
    isSupportedFilter,
    hasPredefinedValues,
    getPredefinedValues,
    getDefaultText,
    buildFilter,
    mergeFilters
};

class IdGenerator {
    constructor() {
        this._id = 1;
    }

    nextId() {
        const id = this._id;
        this._id += 1;
        return id;
    }
}

export default IdGenerator;
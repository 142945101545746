
import React from "react";
const objectHash = require("object-hash");

function parseSearchParams(_text) {
    if(_text[0] === "?") {
        _text = _text.substr(1);
    }
    const tokens = _text.split("&");
    let params = {};
    for (let i = 0; i < tokens.length; i++) {
        let pt = tokens[i].split("=");
        if (pt.length === 1) {
            if (pt[0] && pt[0] !== "") {
                params[pt[0]] = true;
            }
        } else {
            params[pt[0]] = decodeURIComponent(pt[1]);
        }
    }
    return params;
}

function withURLParams(_wrappedComponent, _title) {
    return class extends React.Component {
        static getDerivedStateFromProps(_props, _state) {
            let path;
            if (_props.location)  {
                path = _props.location.pathname;
            }
            let urlParams = {};
            if (_props.match && _props.match.params) {
                urlParams = _props.match.params;
            }
            const queryParams = parseSearchParams(_props.location.search);
            let hash = objectHash({path : path, urlParams : urlParams});
            if (_state.hash !== hash) {
                return {
                    hash : hash,
                    path: path,
                    params : urlParams,
                    queryParams : queryParams
                };
            }
            return null;
        }
        constructor(_props) {
            super(_props);
            this.state = {};
        }


        render() {
            return (
                <_wrappedComponent key={this.state.hash} urlParams={this.state.params} queryParams={this.state.queryParams} {...this.props}/>
            );
        }
    }
}

export default withURLParams;
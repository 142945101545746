
class ChunkAggregator{
    constructor() {
        this._chunks = {};
    }

    addChunk(_id, _idx, _chunk) {
        if (!this._chunks[_id]) {
            this._chunks[_id] = [];
        }
        this._chunks[_id].push({idx: _idx, data: _chunk});
    };

    getChunkCount(_id) {
        if (this._chunks[_id]) {
            return this._chunks[_id].length;
        }
        return 0;
    };

    getChunkData(_id) {
        if (this._chunks[_id]) {
            let dataList = this._chunks[_id];
            dataList.sort((_a, _b) => {return _a.idx-_b.idx;});
            let data = "";
            for (let i = 0; i < dataList.length; i++) {
                data += dataList[i].data;
            }
            delete this._chunks[_id];
            return data;
        }
        return "";
    };
}

export default ChunkAggregator;